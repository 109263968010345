import { create } from "zustand";
import { MOBILE_CUTOFF } from "./constants";

const useDeviceStore = create<{
	device: "mobile" | "desktop";
}>(() =>
	typeof window === "undefined"
		? {
				device: "mobile",
			}
		: {
				device: window.innerWidth < MOBILE_CUTOFF ? "mobile" : "desktop",
			},
);
export default useDeviceStore;
