import { create } from "zustand";

interface StyleStore {
	theme: "Dark" | "Light";
}

const useStyleStore = create<StyleStore>((set, get) => ({
	theme: "Dark",
}));

export default useStyleStore;
