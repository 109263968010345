export type Theme = "Dark" | "Light";

export const Primary: Record<Theme, string> = {
	Dark: "#335CFF",
	Light: "#335CFF",
};
export const Secondary: Record<Theme, string> = {
	Dark: "#CACFD8",
	Light: "#CACFD8",
};
export const Neutral: Record<Theme, string> = {
	Dark: "#2B303B",
	Light: "#2B303B",
};

// WARNING: If you update the background color, make sure you update it in globals.css and app.json and splash_screen.png
export const Background: Record<Theme, string> = {
	Dark: "#08090C",
	Light: "#08090C",
};
export const BackgroundOpacity: Record<Theme, string> = {
	Dark: "#0E121B4D",
	Light: "#0E121B4D",
};
export const BackgroundModal: Record<Theme, string> = {
	Dark: "#0F1117",
	Light: "#0F1117",
};
export const BackgroundModalShimmer: Record<Theme, string> = {
	Dark: "#2F3437",
	Light: "#2F3437",
};
export const BackgroundCard: Record<Theme, string> = {
	Dark: "#181B25",
	Light: "#181B25",
};
export const BackgroundCardShimmer: Record<Theme, string> = {
	Dark: "#2F3437",
	Light: "#2F3437",
};
export const Text: Record<Theme, string> = {
	Dark: "#FFFFFF",
	Light: "#08090C",
};
export const TextContrast: Record<Theme, string> = {
	Dark: "#FFFFFF",
	Light: "#08090C",
};
export const TextMatch: Record<Theme, string> = {
	Dark: "#08090C",
	Light: "#FFFFFF",
};
export const TextLight: Record<Theme, string> = {
	Dark: "#CACFD8",
	Light: "#CACFD8",
};
export const TextDisabled: Record<Theme, string> = {
	Dark: "#525866",
	Light: "#525866",
};
export const TextNavigation: Record<Theme, string> = {
	Dark: "#2B303B",
	Light: "#2B303B",
};
export const Confirmation: Record<Theme, string> = {
	Dark: "#1FC16B",
	Light: "#1FC16B",
};
export const Warning: Record<Theme, string> = {
	Dark: "#FF8447",
	Light: "#FF8447",
};

export const Error: Record<Theme, string> = {
	Dark: "#FB3748",
	Light: "#FB3748",
};
export const Destructive: Record<Theme, string> = {
	Dark: "#FB3748",
	Light: "#FB3748",
};
export const DestructiveOpaque: Record<Theme, string> = {
	Dark: "#FB374899",
	Light: "#FB374899",
};
export const InputPlaceholder: Record<Theme, string> = {
	Dark: "#99A0AE",
	Light: "#99A0AE",
};
export const InputBackground: Record<Theme, string> = {
	Dark: "#0F1117",
	Light: "#0F1117",
};

export const Border: Record<Theme, string> = {
	Dark: "#FFFFFF",
	Light: "#000000",
};
export const ButtonBackground: Record<Theme, string> = {
	Dark: "#2B303B",
	Light: "#2B303B",
};
export const ButtonBackgroundContrast: Record<Theme, string> = {
	Dark: "#FFFFFF",
	Light: "#FFFFFF",
};
export const ButtonBorder: Record<Theme, string> = {
	Dark: "#CACFD8",
	Light: "#CACFD8",
};
export const PillBackground: Record<Theme, string> = {
	Dark: "#2B303B",
	Light: "#2B303B",
};
export const PillBackgroundOpaque: Record<Theme, string> = {
	Dark: "#2B303B99",
	Light: "#2B303B99",
};
export const PillBackgroundAlt: Record<Theme, string> = {
	Dark: "#717784",
	Light: "#717784",
};
export const PillBackgroundShimmer: Record<Theme, string> = {
	Dark: "#2F3437",
	Light: "#2F3437",
};
export const PillBackgroundAltOpaque: Record<Theme, string> = {
	Dark: "#71778499",
	Light: "#71778499",
};
export const MenuBackgroundOpaque: Record<Theme, string> = {
	Dark: "#0F111799",
	Light: "#0F111799",
};
export const MenuSpacerColor: Record<Theme, string> = {
	Dark: "#CACFD826",
	Light: "#CACFD826",
};
export const Disabled: Record<Theme, string> = {
	Dark: "#2B303B",
	Light: "#2B303B",
};
export const BackgroundAlt: Record<Theme, string> = {
	Dark: "#09090C",
	Light: "#09090C",
};
export const BackgroundSearch: Record<Theme, string> = {
	Dark: "#181B25",
	Light: "#181B25",
};
export const TextAlt: Record<Theme, string> = {
	Dark: "#717784",
	Light: "#717784",
};
export const Action: Record<Theme, string> = {
	Dark: "#507CFF",
	Light: "#507CFF",
};
export const BorderHighlight: Record<Theme, string> = {
	Dark: "#09090C",
	Light: "#09090C",
};
export const ShareItemBackground: Record<Theme, string> = {
	Dark: "#0F1117",
	Light: "#0F1117",
};
export const BackgroundOverlay: Record<Theme, string> = {
	Dark: "#0F111780",
	Light: "#0F111780",
};

export const BackgroundNotion: Record<Theme, string> = {
	Dark: "#2F3437",
	Light: "#FFFFFF",
};

export const Glow: Record<Theme, string> = {
	Dark: "#FFFFFF",
	Light: "#FFFFFF",
};

export const Divider: Record<Theme, string> = {
	Dark: "#717784CC",
	Light: "#717784CC",
};

export const PlatformItem: Record<Theme, string> = {
	Dark: "#E7E7E7",
	Light: "#E7E7E7",
};

export const BorderAlt: Record<Theme, string> = {
	Dark: "#CACFD8",
	Light: "#CACFD8",
};

export const OpenGraphMetadataBackground: Record<Theme, string> = {
	Dark: "#D9D9D9",
	Light: "#D9D9D9",
};

export const OpenGraphMetadataText: Record<Theme, string> = {
	Dark: "#000000",
	Light: "#000000",
};

export const Alert: Record<Theme, string> = {
	Dark: "#FB3748",
	Light: "#FB3748",
};

export const ThinBorder: Record<Theme, string> = {
	Dark: "#09090C",
	Light: "#09090C",
};
